import React, {useContext} from 'react';
import insta from '../../assets/img/socials/Instagram-Plain.svg'
import telegram from '../../assets/img/socials/Telegram-Plain.svg'
import facebook from '../../assets/img/socials/Facebook-Plain.svg'
import {Langs} from "../../enums";
import {GlobalContext} from "../../App";


type TContacts = {
    main: string,
    sub: string,
    address1: string,
    address2: string,
    social: string,
    call: string,
    email: string,
};


const contentsMap = new Map<Langs, TContacts>([
    [Langs.RU, {
        main: "Контакты",
        sub: "Позвоните нам, отправьте нам сообщение или посетите наш офис. Мы всегда готовы помочь Вам!",
        address1: "Ташкент Республика Узбекистан",
        address2: "Мирзо-Улугбекский район, ул. Буз, 69",
        social: "Мы в социальных сетях",
        call: "Номер",
        email: "Почта"
    }],
    [Langs.EN, {
        main: "Contacts",
        sub: "Give us a call, send us a message or visit our office. We are always ready to help you!",
        address1: "Tashkent Republic of Uzbekistan",
        address2: "69, Buz street, Mirzo-Ulugbek district",
        social: "Find us at",
        call: "Phone",
        email: "Email"


    }]
]);


function Contacts() {
    const {lang} = useContext(GlobalContext);
    const contents = contentsMap.get(lang) as TContacts;

    return (
        <div id="contacts" className="pb-10 mt-10 scroll-mt-28">
            <div className="text-center">
                <h1 className="text-4xl font-bold mb-7">{contents.main}</h1>
                <p className="pt-6 pb-10">{contents.sub}</p>
            </div>
            <div className="flex flex-wrap justify-between w-10/12 xl:h-[400px] mx-auto">
                <div
                    className="flex flex-col text-center justify-between xl:w-2/5 w-full bg-white rounded shadow-xl">
                    <div className="flex flex-col gap-2.5 mt-10">
                        <h2 className="text-4xl font-bold">{contents.address1}</h2>
                        <p className="text-xl">{contents.address2}</p>
                        <a className="text-xl" href="tel:998971559090">{contents.call}: +998 97 155 90 90</a>
                        <a className="text-xl" href="mailto:sales@conceptsolutions.uz">{contents.email}: sales@conceptsolutions.uz</a>
                    </div>
                    <div>
                        <h2 className="text-4xl xl:mt-0 mt-10 font-bold">{contents.social}</h2>
                        <div className="flex justify-center gap-5 mt-5 mb-10">
                            {/*<a href="https://t.me/mir_temur_bek" target="_blank" rel="noreferrer">*/}
                            <img className="w-8 saturate-0 hover:saturate-100" src={insta} alt="social icons"/>
                            {/*</a>*/}
                            {/*<a href="https://t.me/mir_temur_bek" target="_blank" rel="noreferrer">*/}
                            <img className="w-8 saturate-0 hover:saturate-100" src={telegram} alt="social icons"/>
                            {/*</a>*/}
                            {/*<a href="https://t.me/mir_temur_bek" target="_blank" rel="noreferrer">*/}
                            <img className="w-8 saturate-0 hover:saturate-100" src={facebook} alt="social icons"/>
                            {/*</a>*/}
                        </div>
                    </div>
                </div>
                <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d5993.7051186794915!2d69.31742!3d41.312071!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDE4JzQzLjUiTiA2OcKwMTknMDIuNyJF!5e0!3m2!1sru!2s!4v1734103134927!5m2!1sru!2s"
                    className="xl:w-3/5 w-full h-full"
                    style={{position: 'relative'}}
                ></iframe>

            </div>
        </div>
    );
}

export default Contacts;